// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '~@fortawesome/fontawesome-free/css/all.min.css';

// Variables
@import 'variables';

@import 'fonts';

// Bootstrap
@import '~bootstrap/scss/bootstrap';






@import 'node_modules/vanillajs-datepicker/sass/datepicker';
@import 'node_modules/vanillajs-datepicker/sass/datepicker-bs4.scss';






