@font-face {
  font-family: 'BPG WEB 001 Caps';
  src: url('/fonts/bpg-web-001-caps-webfont.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/bpg-web-001-caps-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/bpg-web-001-caps-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/bpg-web-001-caps-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('/fonts/bpg-web-001-caps-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/bpg-web-001-caps-webfont.svg#bpg_web_001_capsregular') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'ALK Life';
  src: url('/fonts/alk-life-webfont.eot'); /* IE9 Compat Modes */
  src:
    url('/fonts/alk-life-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/alk-life-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/alk-life-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('/fonts/alk-life-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/alk-life-webfont.svg#alk_liferegular') format('svg'); /* Legacy iOS */
}



@font-face {
  font-family: 'BPG Ingiri Arial';
  src: url('/fonts/bpg-ingiri-arial-webfont.eot'); /* IE9 Compat Modes */
  src:
    url('/assets/fonts/bpg-ingiri-arial-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/bpg-ingiri-arial-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/bpg-ingiri-arial-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('/fonts/bpg-ingiri-arial-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/bpg-ingiri-arial-webfont.svg#bpg_ingiri_arialregular') format('svg'); /* Legacy iOS */
}

